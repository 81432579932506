import { useState } from "react"
import { TopBar } from "../../_shared/TopBar"
import './webinars-details.scss'
import Lottie from "lottie-react";
import anim from "../../assets/anim/success.json";
import thumb from "../../assets/anim/thumb.json";
import { firebaseApp } from "../../system/firebase";
import { get, getDatabase, onValue, ref, serverTimestamp, set } from "firebase/database";

const RouteWebinarsDetails = () => {
    const [screen, setScreen] = useState(0);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const database = getDatabase(firebaseApp);

    const register = async () => {
        if (!email.trim().length > 0 || !name.trim().length > 0) {
            alert("please fill in the details");
            return;
        }
        setLoading(true);

        const regRef = ref(database, 'EVENTS/SUBSCRIBERS/' + email.trim().replace(/\W/g, ''));
        get(regRef).then(async(snapshot) => {
            if (!snapshot.exists()) {
                await set(ref(database, 'EVENTS/SUBSCRIBERS/' + email.trim().replace(/\W/g, '')), {
                    name: name.trim(),
                    email: email.toLowerCase().trim(),
                    "time": serverTimestamp(),
                });
                setScreen(2);
                setLoading(false);
            }
            if (snapshot.exists()) {
                setScreen(3);
                setLoading(false);
            }
        }).catch((error) => {
            console.error(error);
        });






    }
    const confirmRegistration = async () => {
        if (!email.trim().length > 0) {
            alert("please fill in your email");
            return;
        }
        setLoading(true);

        const regRef = ref(database, 'EVENTS/SUBSCRIBERS/' + email.trim().replace(/\W/g, ''));
        get(regRef).then(async(snapshot) => {
            const data = snapshot.val();
            console.log("data", data);
            if (snapshot.exists()) {
                setScreen(2);
                setLoading(false);
            }
            if (!snapshot.exists()) {
                alert("Couldn't find your account. Please register");
                setLoading(false);
            }
        }).catch((error) => {
            console.error(error);
        });


    }
    return <div className="screen-webinars">
        <TopBar />
        {screen === 0
            &&
            <>
                <div className="webinar-rl">
                    <div className="box-root  padding-bottom--24 flex-flex flex-justifyContent--center">
                        <h1 className="top-title"><a href="#" rel="dofollow">Event Registration</a></h1>
                    </div>
                    <p>Complete the form below to register for free</p>
                    <div className="formbg-outer">
                        <div className="formbg">
                            <div className="formbg-inner padding-horizontal--48">
                                <div id="stripe-login">
                                    <div className="field padding-bottom--24">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="field padding-bottom--24">
                                        <label htmlFor="name">Full Name</label>
                                        <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="field padding-bottom--24">
                                        <input onClick={register} type="submit" name="submit" value="Continue" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-link padding-top--24">
                            <span className="registered-button" onClick={() => setScreen(1)}>Already registered? <a href="#">Get the link</a></span>
                            <div className="listing padding-top--24 padding-bottom--24 flex-flex center-center">
                                <span><a href="/">© ABSK Africa</a></span>
                                <span><a href="/">Contact</a></span>
                                <span><a href="/">Privacy & terms</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && <>
                    <div className="modal-bg">
                        <div className="">
                            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            <h3>saving your data</h3>
                        </div>
                    </div>
                </>}
            </>
        }
        {screen === 1
            &&
            <>
                <div className="webinar-rl">
                    <div className="box-root  padding-bottom--24 flex-flex flex-justifyContent--center">
                        <h1 className="top-title"><a href="#" rel="dofollow">Welcome Back</a></h1>
                    </div>
                    <p>Kindly enter your email to get the link</p>
                    <div className="formbg-outer">
                        <div className="formbg">
                            <div className="formbg-inner padding-horizontal--48">
                                <div id="stripe-login">
                                    <div className="field padding-bottom--24">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="field padding-bottom--24">
                                        <input onClick={confirmRegistration} type="submit" name="submit" value="Continue" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-link padding-top--24">
                            <span onClick={() => setScreen(0)}>Not registered? <a href="#">Go Back</a></span>

                            <div className="listing padding-top--24 padding-bottom--24 flex-flex center-center">
                                <span><a href="/">© ABSK Africa</a></span>
                                <span><a href="/">Contact</a></span>
                                <span><a href="/">Privacy & terms</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && <>
                    <div className="modal-bg">
                        <div className="">
                            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            <h3>getting the link</h3>
                        </div>
                    </div>
                </>}
            </>
        }
        {screen === 2
            &&
            <>
                <div className="webinar-rl">
                    <div className="success-image">
                        <Lottie animationData={anim} />
                    </div>
                    <div className="box-root  padding-bottom--24 flex-flex flex-justifyContent--center">
                        <h1 className="top-title"><a href="#" rel="dofollow">Successful!</a></h1>
                    </div>
                    <p className="iinfo">Below is the meeting link for the next meeting. We will send you an email before each webinar.</p>
                    <a className="meeting-link" target="_blank" href="https://us02web.zoom.us/j/88541783288?pwd=QXrygcGWG8y4RhKXQHcAtNl1RNGHK7.1">https://us02web.zoom.us/j/88541783288?pwd=QXrygcGWG8y4RhKXQHcAtNl1RNGHK7.1</a>
                </div>
            </>
        }
        {screen === 3
            &&
            <>
                <div className="webinar-rl">
                    <div className="success-image">
                        <Lottie animationData={thumb} />
                    </div>
                    <div className="box-root  padding-bottom--24 flex-flex flex-justifyContent--center">
                        <h1 className="top-title"><a href="#" rel="dofollow">Already Registered!</a></h1>
                    </div>
                    <p className="iinfo">Looks like you have already registered for the series. Here is your link:</p>
                    <a className="meeting-link" target="_blank" href="https://us02web.zoom.us/j/88541783288?pwd=QXrygcGWG8y4RhKXQHcAtNl1RNGHK7.1">https://us02web.zoom.us/j/88541783288?pwd=QXrygcGWG8y4RhKXQHcAtNl1RNGHK7.1</a>
                </div>
            </>
        }

    </div>


}
export default RouteWebinarsDetails;