import { getDatabase, onValue, orderByChild, query, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { firebaseApp } from "../../system/firebase";
import { timeStampReadable, timeStampReadableWithTime, timestampToInputFormat } from "../../system/general_methodology";

const SubrouteEvents = () => {
    const [subscribers, setsubscribers] = useState([]);
    useEffect(() => {
        const database = getDatabase(firebaseApp);
        // const subscribersRef = ref(database, 'EVENTS/SUBSCRIBERS');
        const subscribersRef = query(ref(database, 'EVENTS/SUBSCRIBERS'), orderByChild('time'));
        onValue(subscribersRef, (snapshot) => {
            var newMessages = [];
            var emailList = [];
            snapshot.forEach((snap) => {
                newMessages.push(snap.val());
                // var i = 0;


                // console.log("snap",snap.val()[0]);
                emailList.push(snap.val().email)

            });
            console.log("newMessages", newMessages);
            console.log("emailList", emailList);
            setsubscribers(newMessages.reverse());
        });

    }, []);
    return <div className='main-content'>
        <div className='main-header'>
            <h1 className='noPadding main-heading'><span className='title-thin'>Admin</span> Panel </h1>
            <div className='main-header-right'>
                <div>ABSK ADMIN</div>
                <div className='profile-small'></div>
            </div>
        </div>
        <div className='main-content-inner' style={{ justifyContent: "right", paddingRight: 112 }}>
            <div style={{ textAlign: "right" }}>
                {subscribers.length} total subscribers
            </div>
        </div>

        <div className='main-content-inner messages-parent'>
            <div className="messages-list">
                {subscribers?.map((item, index) => {
                    // console.log("item", item);
                    return <div className="message-wrap">
                        <div className="message" key={index}>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                            <div className="time">{timeStampReadable(item.time)}</div>
                        </div>
                        {/* <div className="thread">
                            {mess.map((data) => {
                                return <div>
                                    <div className="mess-time">{timeStampReadableWithTime(data.sent)}</div>
                                    <div>{data.message}</div>
                                </div>
                            })}
                        </div> */}
                    </div>
                })}
            </div>

        </div>

    </div>
}
export default SubrouteEvents;