import { FaEnvelopeOpen, FaFacebook, FaFacebookMessenger, FaFacebookSquare, FaInstagram, FaLinkedin, FaMapPin, FaPhone, FaPlay, FaRegEnvelopeOpen, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { IndexPeople } from './people';
import ViewSignin from './signin';
import ViewContact from './contact';
import { useState } from 'react';
import { TopBar } from '../../_shared/TopBar';

const RouteIndex = () => {
    const [videoShowing, setVideoShowing] = useState(false);
    const [ajabsShowing, setAjabsShowing] = useState(false);

    return (
        <div>
            <section className="navbar custom-navbar navbar-fixed-top" role="navigation">
                <TopBar />
                <div className="container">
                    <div className="navbar-header">
                        <button className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                            <span className="icon icon-bar" />
                            <span className="icon icon-bar" />
                            <span className="icon icon-bar" />
                        </button>
                        <a href="/index" className="navbar-brand">
                            <span className="image-text">ABSK</span> <sup>Africa</sup>
                        </a>
                    </div>
                    {/* MENU LINKS */}
                    <div className="collapse navbar-collapse">
                        <ul className="nav navbar-nav navbar-nav-first">
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li className="active"><a href="#home" className="smoothScroll">Home</a></li>
                            <li><a href="/webinars" className="smoothScroll">Events</a></li>
                            <li><a href="#journal" className="smoothScroll" onClick={()=>setAjabsShowing(!ajabsShowing)}>Ajabs Journal</a></li>
                            <li><a href="#counselling" className="smoothScroll">Counselling</a></li>
                            <li><a href="#about" className="smoothScroll">About</a></li>
                            <li><a href="#contact" id="open-mailer" className="smoothScroll">Contacts</a></li>
                            <li className="section-btn african-but"><a href="#" data-toggle="modal" data-target="#modal-form">Members / Sign in</a></li>
                        </ul>
                    </div>
                </div>
            </section>
            {ajabsShowing
                &&
                <>
                    <div className='ajabs-modal'>
                        
                        <div className='modal-inner'>
                        <div className="close-bt" onClick={()=>setAjabsShowing(!ajabsShowing)}>x</div>
                            <h2  className="gold-gradient-text">A Call for Journal Papers </h2>
                            <p>African Journal of Applied Behavioural Sciences (AJABS) is a bi-anual journal on topical contemporary issues in Behavioural sciences. Prospective authors must point out the practical application component of their journal paper. Publications will be in May and December of every year.</p>
                            <p>Cost of application is <b>$100</b>.</p>
                            <p>Contact: <b style={{ color: "goldenrod" }}>journal@abskafrica.com</b> </p>
                        </div>
                    </div>
                </>
            }

            <section id="home" data-stellar-background-ratio="0.5">
                <div className="overlay" />
                <div className="container">
                    <div className="row mobile-inverse">
                        <div className="col-md-5 col-sm-12">
                            <div className="home-info">
                                <h6 className="gold-gradient-text">Welcome to ABSK Africa!</h6>
                                <div>
                                    <h1 className="gold-gradient-text">
                                        <div>A</div>ssociation Of <div>B</div>ehavioural <div>S</div>cientists <br />
                                        <div>K</div>enya, <div>Africa</div>.
                                    </h1>
                                </div>
                                <a href="#courses" className="btn section-btn smoothScroll left-button mobile-hidden">Course
                                    Registration</a>
                                <a href="/webinars" className="btn section-btn transparent-btn smoothScroll mobile-hidden">Events</a>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <div className="home-video">
                                <div onClick={() => setVideoShowing(!videoShowing)} className="play-but-wrap play-video">
                                    <FaPlay />
                                </div>
                                <a className="watch-more-but" target="_blank" href="https://www.youtube.com/channel/UCjfKgOyMXNoKaTZmQqjuL8A">Click to watch more
                                    videos</a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    videoShowing && <div className='yt-modal' onClick={() => setVideoShowing(false)}>
                        <iframe width="920" height="615"
                            src="https://www.youtube.com/embed/hN1UdH2LwMk?autoplay=1&mute=1">
                        </iframe>
                    </div>
                }
            </section>
            <section className="wording-segment">
                <div className="wording-themselves">
                    Psychology Innovation in Research and Practice
                </div>
            </section>
            <section id="courses">
                <div className="section-illustration study-illustration" />
                <div>
                    <div className="sect-title">Enrol for short courses</div>
                    <div className="sect-sub-title">
                        Below are ABSK certificate courses you can enrol for today.
                    </div>
                    <ul className="course-list">
                        <li className="course-item">
                            <div className="course-ic-wrap">
                                <div className="course-ic care-giver-bg" />
                            </div>
                            <div className="course-item-right">
                                <div className="course-title">A Certificate Course for Home-based Care Givers</div>
                                <div className="course-duration">Online, 3 Months</div>
                                <div className="course-mode">6pm - 8pm (Evening classes)</div>
                                {/* <div class="course-mode">Fees:1000KES (Application) + 50,000 KES(Tuition)</div> */}
                                <a className="course-but section-btn african-but" id="learn-more-bt-care-givers" style={{ padding: '10px 20px' }} data-toggle="modal" data-target="#learn-more-form">Learn
                                    More</a>
                            </div>
                        </li>
                        <li className="course-item">
                            <div className="course-ic-wrap">
                                <div className="course-ic young-couple-bg" />
                            </div>
                            <div className="course-item-right">
                                <div className="course-title">A Course for Young Couples</div>
                                <div className="course-duration">Online, 1 Month</div>
                                <div className="course-mode">6pm - 8pm (Evening classes)</div>
                                {/* <div class="course-mode">Fees:1000KES (Application) + 50,000 KES(Tuition)</div> */}
                                <a className="course-but section-btn african-but" id="learn-more-bt-young-couples" style={{ padding: '10px 20px' }} data-toggle="modal" data-target="#learn-more-form">Learn
                                    More</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="counselling">
                <h1 className="image-text">Counselling &amp; Psychotherapy</h1>
                <div className="sect-sub-title">
                    We offer Counselling services including online sessions.
                </div>
                <ul className="counselling-list">
                    <li className="counselling-item">
                        <div className="counselling-free-label">FREE</div>
                        <div className="counselling-item-icon emergency-img" />
                        <div className="counselling-item-body">
                            <div className="course-title">Crisis Counselling</div>
                            <div className="counselling-item-description"> suicide</div>
                            <a className="course-but section-btn african-but" id="learn-more-bt-care-givers" style={{ padding: '10px 50px' }} data-toggle="modal" data-target="#services-form">Contact</a>
                        </div>
                    </li>
                    <li className="counselling-item">
                        <div className="counselling-item-icon routine-img" />
                        <div className="counselling-item-body">
                            <div className="course-title">Routine Counselling</div>
                            <div className="counselling-item-description"> marital, career, personal</div>
                            <a className="course-but section-btn african-but" id="learn-more-bt-routine-counselling" style={{ padding: '10px 50px' }} data-toggle="modal" data-target="#services-form">Contact</a>
                        </div>
                    </li>
                    <li className="counselling-item">
                        <div className="counselling-item-icon special-img" />
                        <div className="counselling-item-body">
                            <div className="course-title">Special Needs</div>
                            <div className="counselling-item-description"> mental health, special children</div>
                            <a className="course-but section-btn african-but" id="learn-more-bt-special-counselling" style={{ padding: '10px 50px' }} data-toggle="modal" data-target="#services-form">Contact</a>
                        </div>
                    </li>
                </ul>
            </section>
            <section id="about" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <h1 className="image-text">Who Are We?</h1>
                <p className="who-we">We are an association of Behavioural Scientists committed to all round development of
                    the person.
                    Our mission is to empower people to take responsibility of their behaviour, be
                    conscious of others, of
                    nature, and of the future, in the spirit of ubuntu.
                </p>
                <div id="the-members" className="the-members">
                    <IndexPeople />
                </div>
            </section>
            <ViewContact />

            {/* FOOTER */}
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <div className="footer-thumb footer-info">
                                <h2>ABSK Africa</h2>
                                <p>Association of Behavioural Scientists - Psychology Innovation in Research and Practice.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <div className="footer-thumb">
                                <h2>Company</h2>
                                <ul className="footer-link">
                                    <li><a href="#">About Us</a></li>
                                    <li><a href="#">Join our team</a></li>
                                    <li><a href="#">Read Blog</a></li>
                                    <li><a href="#">Press</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <div className="footer-thumb">
                                <h2>Services</h2>
                                <ul className="footer-link">
                                    <li><a href="#">Links</a></li>
                                    <li><a href="#">Rec</a></li>
                                    <li><a href="#">Support</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-4">
                            <div className="footer-thumb">
                                <h2>Find us</h2>
                                <p>ABSK Offices, <br /> CBD, Nairobi Kenya</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="footer-bottom">
                                <div className="col-md-6 col-sm-5">
                                    <div className="copyright-text">
                                        <p>Copyright © 2024 ABSK Africa</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-7">
                                    <div className="phone-contact">
                                        <p>Call us <span>(+254) 721 687 093</span></p>
                                    </div>
                                    <ul className="social-icon">
                                        <li><a href="https://www.facebook.com/absk" attr="facebook icon" /><FaFacebook color="#d9d9d9" style={{ marginRight: 16 }} /></li>
                                        <li><a><FaTwitter /></a></li>
                                        <li><a><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* MODAL */}
            <section className="modal fade" id="learn-more-form" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-popup learn-more-modal-parent">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="modal-title" style={{ marginBottom: 0 }}>
                                            <h2>Payment Information</h2>
                                        </div>
                                        <div className="tab-content">
                                            <p>Bank Details:</p>
                                            <p className="payment-information-p"><span>A/C Name:</span> National Bank Of
                                                Kenya</p>
                                            <p className="payment-information-p"><span>A/C Number:</span> 01201244471500
                                            </p>
                                            <p className="hide-on-session">Fees for the course:</p>
                                            <p className="payment-information-p"><b id="instruction-field">Registration:</b> <span id="session-fees">1,000 KES</span></p>
                                            <p className="payment-information-p hide-on-session">Tuition Fees: <span id="course-fees">50,000 KES</span> </p>
                                            <p className="payment-information-bottom">For further assistance, contact us
                                                through our email: <span>support@abskafrica.com</span> or call us
                                                using the number <span>+254 721 687 093</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div></section>
            {/* MODAL */}

            <section className="modal fade" id="services-form" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel3" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-popup learn-more-modal-parent">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="modal-title" style={{ marginBottom: 0 }}>
                                            <h2>Contact Information</h2>
                                        </div>
                                        <div className="tab-content">
                                            <p>Call the following number</p>
                                            <p className="payment-information-p" style={{ fontSize: 'large', color: 'orange' }}>
                                                <strong>+254 788 828 183</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div></section>
            {/* MODAL */}

            <ViewSignin />
        </div>
    );
}
export default RouteIndex;